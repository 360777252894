import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useGAEventTracker } from '../helpers/UseGAEventTracker';

const Slider = (props) => {
  
  const gaEventTracker = useGAEventTracker('Slider');

  return(
    <div className="swiper-news-container">
      <Swiper
        className="swiper-container swiper-news"
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        navigation={{
          prevEl: '.swiper-news-button-prev',
          nextEl: '.swiper-news-button-next'
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination'
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        { (props.items.length) ? props.items.map((s,key) => (
            <SwiperSlide key={key}>
              <div className="news-card">
                <a href={ s.url } style={{ display: 'block' }} onClick={()=>gaEventTracker(s.event,s.desc)} rel="noreferrer" target="_blank"><img src={ props.mainURL + s.img } alt={ s.title } title={ s.title } /></a>
              </div>
            </SwiperSlide>
        )) : null }
      </Swiper>
      <div className="swiper-pagination"></div>
      <div className="swiper-news-button-prev">
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="53.5" y="53.5" width="53" height="53" rx="9.5" transform="rotate(180 53.5 53.5)" fill="#141616" fillOpacity="0.8"></rect>
          <rect x="53.5" y="53.5" width="53" height="53" rx="9.5" transform="rotate(180 53.5 53.5)" stroke="white"></rect>
          <path d="M27 35L28.41 33.59L22.83 28H35V26H22.83L28.41 20.41L27 19L19 27L27 35Z" fill="white"></path>
        </svg>
      </div>
      <div className="swiper-news-button-next">
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="53" height="53" rx="9.5" fill="#141616" fillOpacity="0.8"></rect>
          <rect x="0.5" y="0.5" width="53" height="53" rx="9.5" stroke="white"></rect>
          <path d="M27 19L25.59 20.41L31.17 26H19V28H31.17L25.59 33.59L27 35L35 27L27 19Z" fill="white"></path>
        </svg>
      </div>
    </div>
  )
};

export default Slider;